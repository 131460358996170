<template>
  <v-app-bar app color="background lighten-1" elevate-on-scroll>
    <template v-slot:img="{ props }">
      <v-img v-bind="props"></v-img>
    </template>

    <v-app-bar-nav-icon @click="openNavBar()" v-if="windowsWidth > 900" />

    <v-toolbar-title>{{
      this.title.substring(0, 1).toUpperCase() + this.title.substring(1)
    }}</v-toolbar-title>

    <v-spacer></v-spacer>
    <v-btn
      color="transparent"
      depressed
      rounded
      class="text-capitalize"
      v-if="windowsWidth > 900"
    >
      <v-avatar size="32" class="mr-3" color="primary" dark>
        <v-card-title class="white--text">
          {{ this.name.substring(0, 1) }}
        </v-card-title>
      </v-avatar>
      <div>
        {{ this.name }}
      </div>
    </v-btn>

    <!--Campana-->
    <!-- <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          small
          color="background"
          elevation="2"
          class="mx-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

    <!--Modo Oscuro-->
    <v-btn
      fab
      small
      color="background"
      elevation="2"
      class="mx-2"
      @click="toogleDarkMode"
    >
      <v-icon>mdi-brightness-3</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "app-bar",

  props: ["navbar", "windowsWidth"],

  data: () => ({
    title: "",
    name: "",
    avatar: "",
    drawer: true, //Creado
    items: [{ title: "Sin notificaciones" }],
    closeOnClick: true,
  }),
  watch: {
    $route: function(val) {
      let route = val.path;
      if (route === "/") {
        this.title = "Inicio";
      } else {
        route = route.slice(1);
        this.title = route.replace(/[/]/g, " - ");
      }
    },
  },

  mounted() {
    let route = this.$route.path;
    if (route === "/") {
      this.title = "Inicio";
    } else {
      route = route.slice(1);
      this.title = route.replace(/[/]/g, " - ");
    }
    this.getUserName();
  },

  methods: {
    openNavBar() {
      this.$emit("onNavBar", !this.navbar);
    },
    toogleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$emit("onDark", this.$vuetify.theme.dark);
    },
    getUserName() {
      String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
      };
      this.name = sessionStorage.getItem("username") || "Usuario";
    },
  },
};
</script>

<style></style>
